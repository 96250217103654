<template>
  <nav class="navbar">
    <div class="logo">
      <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
      <span class="title">Don Apoyo, S.A.P.I. de C.V. SOFOM, E.N.R.</span>
    </div>
    <ul class="nav-links">
      <li
        :class="{ 'active-link': isActive('/') }" class="Li1"
    
      >
        <router-link to="/" class="nav-link1">
          <span class="routeText1">Comenzar</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/history' }" class="Li2">
        <router-link to="/history" class="nav-link2">
          <span class="routeText2">Entendernos</span>
        </router-link>
      </li>
      <li
        :class="{ 'active-link': $route.path === '/question' }" class="Li3"
      >
        <router-link to="/question" class="nav-link3">
          <span class="routeText3">Problema Comun</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
        <router-link to="/protocol" class="nav-link4">
          <span class="routeText4">Acuerdo de Privacidad</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
   
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  height: 90px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #08358B;
  min-width: 1400px;
  max-width: 1920px;
  /* 将导航栏固定在页面 */
  top:0;
  position: sticky;
  z-index: 1000;
  /* overflow: auto; */
}
.logo {
  font-weight: bold;
  color: black;
  display: flex; /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center; /* 垂直居中对齐 */
  padding-left: 10px;
}

.logoImg {
  width: 50px;
  height: 50px;
}
.title {
  width: 530px;
  height: 24px;
  font-size: 18px;
  font-family: SF Pro Display, SF Pro Display;
  font-weight: 600;
  color: #000000;
  line-height: 23px;
  /* padding-left: 16px; */
  /* border: 1px solid red; */
  padding-left: 90px;
}
.routeText1,
.routeText2,
.routeText3,
.routeText4 {
  font-size: 16px;
  font-family: SF Pro Display, SF Pro Display;
  /* display: flex; 
  align-items: center; 
  text-align: center; */
}
/* .routeText1 {
  width: 100px;
  padding-left: 8px;
  padding-right: 8px;
}
.routeText2 {
  width: 120px;
  padding-left: 8px;
  padding-right: 8px;
}
.routeText3 {
  width: 150px;
  padding-left: 8px;
  padding-right: 8px;
}
.routeText4 {
  width: 180px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
} */
/* .nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  height: 49px;
} */

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4 {
  text-decoration: none;
 /* 将链接文字转为大写 */
  /* text-transform: uppercase; */
  font-size: 16px;
  font-family: SF Pro-Semibold;
  /* text-align: center */
}

/* .nav-link1 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #170101;
  line-height: 39px;
}

.nav-link2 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
}
.nav-link3 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
}
.nav-link4 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
} */
.nav-links{
  list-style: none;
  display: flex;
}

.nav-links li {
  display: flex;
  padding: 8px 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  align-items: center;
  justify-content: center;
}
.active-link {
  background: url("@/page/donghaisoftware/components/img/Rectangle2.png");
  background-size: 100% 100%; /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
}
.active-link .routeText {
  color: #08358B;
}
.active-link span {
  color: #08358B;
}
.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.title{
  color: #FFFFFF;
}

.navbar{
  max-width: 1620px;
}

.Li1 {
  width: 100px;
}
.Li2 {
  width: 120px;
}
.Li3 {
  width: 150px;
}
.Li4 {
  width: 190px;
}

</style>