<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="productImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>Política de privacidad</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import productImg1 from "@/page/tongshiimportacion/components/img/5_1.png";
import productImg2 from "@/page/wisementpresta/components/img/4_2.png";

export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      text:`Esta página le informa sobre nuestras políticas sobre la recopilación, uso y divulgación de información personal cuando utiliza nuestro Servicio.

No usaremos ni compartiremos su información con nadie excepto como se describe en esta Política de Privacidad.

Utilizamos su información personal para proporcionar y mejorar el Servicio. Al utilizar los Servicios, usted acepta la recopilación y el uso de información de acuerdo con esta Política. A menos que se defina lo contrario en esta Política de privacidad, los términos utilizados en esta Política de privacidad tienen el mismo significado que en nuestros Términos y condiciones de uso.

Recopilación y uso de información.

Al utilizar nuestros Servicios, es posible que le solicitemos que nos proporcione cierta información de identificación personal que pueda usarse para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros, su dirección de correo electrónico, nombre, número de teléfono, dirección postal, número de Seguro Social, información de cuenta bancaria y otra información ("Información personal").

Recopilamos esta información para brindarle servicios, identificarlo y comunicarnos con usted, responder a sus solicitudes/consultas, atender sus órdenes de compra y mejorar nuestros servicios.

Usted comprende que toda la información personal que proporcione a través de la Aplicación, el Sitio web o todos los informes, resultados y/o información creada, compilada, analizada y/o derivada de dichos datos son propiedad exclusiva de Our Financials y se consideran de nuestra propiedad. Información confidencial en virtud de este Acuerdo. Tenemos derecho a utilizar, comercializar y volver a comercializar cualquier dato sin ninguna obligación adicional para con usted.

Registrar datos

También podemos recopilar información que su navegador envía cuando visita nuestros Servicios o accede a los Servicios desde un dispositivo móvil ("Datos de registro").

Estos datos de registro pueden incluir la dirección del Protocolo de Internet (“IP”) de su computadora, el tipo de navegador, la versión del navegador, las páginas que visita mientras usa nuestro Servicio, la hora y fecha de su visita, y el tiempo dedicado a esas páginas y otra información. . Datos estadísticos.
Cuando accede al Servicio a través de un dispositivo móvil, estos Datos de registro pueden incluir información como el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, su tipo de dispositivo móvil y otros información. El navegador de Internet móvil que utiliza y otras estadísticas.

Además, podemos utilizar servicios de terceros (como Google Analytics) para recopilar, monitorear y analizar esta información para mejorar la funcionalidad de nuestros Servicios. Estos proveedores de servicios externos tienen sus propias políticas de privacidad que rigen cómo utilizan dicha información.

Información sobre la ubicación

Podemos usar y almacenar información sobre su ubicación según los permisos que haya configurado en su dispositivo. Utilizamos esta información para proporcionar la funcionalidad de nuestro Servicio, mejorar y personalizar nuestro Servicio. Cuando utiliza nuestros Servicios, puede habilitar o deshabilitar los servicios de ubicación en cualquier momento a través de la configuración de su dispositivo móvil.

Galletas
Las cookies son archivos que contienen una pequeña cantidad de datos, que pueden incluir un identificador único anónimo. Las cookies se envían a su navegador desde el sitio web y se transfieren a su dispositivo. Utilizamos cookies para recopilar información para mejorar nuestros servicios.

Puede indicarle a su navegador que rechace todas las cookies o que indique cuándo se envía una cookie. La función de Ayuda en la mayoría de los navegadores proporciona información sobre cómo aceptar cookies, desactivarlas o notificarle cuando recibe una nueva cookie.

Si no acepta las cookies, es posible que no pueda utilizar ciertas funciones de nuestros Servicios y le recomendamos que las deje activadas.

remarketing conductual

   Utilice servicios de remarketing para anunciarle en sitios web de terceros después de que visite nuestros Servicios. Nosotros y nuestros proveedores externos utilizamos cookies para informar, optimizar y publicar anuncios basados en sus visitas anteriores a nuestros Servicios.

proveedor de servicio

Podemos emplear empresas e individuos de terceros para facilitar nuestro Servicio, proporcionar el Servicio en nuestro nombre, realizar servicios relacionados con el Servicio (incluidos los servicios de pago) y/o ayudarnos a analizar cómo se utiliza nuestro Servicio.

Estos terceros tienen acceso a su información personal sólo para realizar tareas específicas en nuestro nombre y pueden utilizar la información compartida de acuerdo con sus propias políticas de privacidad.

comunicación

Podemos utilizar su información personal para contactarlo con boletines informativos, materiales promocionales o de marketing y otra información que pueda ser de su interés. Puede optar por no recibir cualquiera o todas estas comunicaciones comunicándose con nosotros.

atender a la ley

Divulgaremos su información personal si así lo exige la ley o una citación judicial o si creemos que dicha acción es necesaria para cumplir con la ley y las solicitudes razonables de las autoridades o para proteger la seguridad o integridad de nuestros Servicios.

Seguridad

La seguridad de su información personal es importante para nosotros y nos esforzamos por implementar y mantener procedimientos y prácticas de seguridad razonables y comercialmente aceptables, apropiados a la naturaleza de la información que almacenamos para protegerla del acceso no autorizado, la destrucción, el uso, la modificación o la divulgación. .

Enlaces a otros sitios web

Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio web de ese tercero. Le recomendamos encarecidamente que revise la política de privacidad de cada sitio web que visite.

No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.

privacidad de los niños

Sólo las personas mayores de 18 años están autorizadas a acceder a nuestros Servicios. Nuestros Servicios no están dirigidos a nadie menor de 13 años ("Niños").

No recopilamos intencionalmente información de identificación personal de niños menores de 13 años. Si es padre o tutor y se entera de que su hijo nos ha proporcionado información personal, comuníquese con nosotros. Si descubrimos que hemos recopilado información personal de un niño menor de 13 años sin el consentimiento de los padres, tomaremos medidas para eliminar esa información de nuestros servidores.

Cambios a esta Política de Privacidad

Nos reservamos el derecho de actualizar o cambiar nuestra Política de Privacidad en cualquier momento, y usted debe revisar esta Política de Privacidad periódicamente. Su uso continuado de los Servicios después de que publiquemos cualquier modificación a la Política de Privacidad en esta página constituirá su reconocimiento de las modificaciones y su acuerdo de cumplir y estar sujeto a la Política de Privacidad modificada.`,

      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3400px;
  background-color: "#FFFFFF";
  border-radius: 32px;
  margin: auto;
  margin-top: -50px;
  transform: scale(87%,95%);
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
 /* background-image: url(./components/img/Rectangle1.png); */
 /* background-position:0 40px; */
 /* background-repeat: no-repeat; */
/* border-bottom: 5px solid #04358A; */
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
</style>